'use client';

import { useEffect, useState } from 'react';

import type { User } from '@supabase/supabase-js';

import Gradient from 'rgt';

import { cn } from '@kit/ui/utils';

import { AppLogo } from '~/components/app-logo';

import { SiteHeaderAccountSection } from './site-header-account-section';
import { SiteNavigation } from './site-navigation';

const SHOW_NAVIGATION = true;
const SHOW_ACCOUNT_SECTION = true;
const SHOW_MARKER_SHAPE = false;
const SHOW_NEW_HEADLINE = true;

export function SiteHeader(props: { user?: User | null }) {
  const [showElement, setShowElement] = useState(SHOW_NEW_HEADLINE);

  useEffect(() => {
    const handleScroll = () => {
      const triggerPoint = 400;
      if (window.scrollY > triggerPoint) {
        setShowElement(true);
      } else {
        setShowElement(SHOW_NEW_HEADLINE);
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={
        'sticky top-0 isolate z-10 w-full bg-background/80 shadow-sm backdrop-blur-md dark:bg-background/80 dark:shadow-primary/10'
      }
    >
      <div className={'xs:pl-4 px-2 lg:px-8'}>
        <div className="grid h-14 grid-cols-[45px_auto_1fr] items-center sm:grid-cols-[75px_auto_1fr] md:grid-cols-[1fr_auto_1fr]">
          <div className="flex items-center justify-center sm:justify-start">
            <AppLogo />
            <div
              className={cn(
                'transition-opacity duration-500 lg:ml-2',
                showElement
                  ? 'pointer-events-auto opacity-100 max-sm:block'
                  : 'pointer-events-none opacity-0 max-sm:opacity-100',
              )}
            >
              <h1 className="text-[1.7rem] font-semibold leading-none tracking-tight text-white lg:text-3xl">
                <span
                  className={cn(
                    'ml-2 mt-2 md:mt-6',
                    SHOW_MARKER_SHAPE && 'marker-highlight',
                  )}
                >
                  <Gradient dir="left-to-right" from="#ee6370" to="#f5c066">
                    Content
                  </Gradient>
                  {` `}
                  <Gradient dir="left-to-right" from="#95cd7f" to="#72e6dd">
                    Flywheel
                  </Gradient>
                  {SHOW_MARKER_SHAPE && (
                    <svg
                      xmlns="//www.w3.org/2000/svg"
                      version="1.1"
                      className="svg-filters hidden min-h-[50px]"
                    >
                      <defs>
                        <filter id="marker-shape">
                          <feTurbulence
                            type="fractalNoise"
                            baseFrequency="0 0.15"
                            numOctaves="1"
                            result="warp"
                          />
                          <feDisplacementMap
                            xChannelSelector="R"
                            yChannelSelector="G"
                            scale="30"
                            in="SourceGraphic"
                            in2="warp"
                          />
                        </filter>
                      </defs>
                    </svg>
                  )}
                </span>
              </h1>
            </div>
          </div>

          <div className={'order-first md:order-none'}>
            {SHOW_NAVIGATION && <SiteNavigation />}
          </div>

          <div className={'flex items-center justify-end space-x-1'}>
            {SHOW_ACCOUNT_SECTION && (
              <SiteHeaderAccountSection user={props.user ?? null} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
